var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.forDialog
    ? _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "font-weight-bold",
                          attrs: {
                            icon: "",
                            loading: _vm.queryPreviewLoading,
                            color: "secondary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getQueryResults(_vm.qid)
                            },
                          },
                        },
                        on
                      ),
                      [_c("v-icon", [_vm._v("mdi-magnify-plus-outline")])],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            1963989478
          ),
        },
        [_c("span", [_vm._v("Preview results")])]
      )
    : _c(
        "v-btn",
        {
          attrs: {
            color: "primary",
            loading: _vm.queryPreviewLoading,
            text: "",
          },
          on: {
            click: function ($event) {
              return _vm.getQueryResults(_vm.qid)
            },
          },
        },
        [_vm._v("Preview results")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }